import React, { useState } from 'react';
import styles from './index.module.css'
import { Link } from "gatsby"
import cls from 'classnames'
// import iconimag from '../../images/app/top-icon.png'

const Header = ({page}) => {
  console.log('Page',page)
  const [openStatus, setOpenStatus] = useState(false)
  const toggleOpenStatus = () => {
    // console.log(1111, openStatus)
    setOpenStatus(!openStatus)
  }
  return (
    <div className={styles.vacant}>
      <div className={styles.topBox}>
        <img
          className={styles.logo}
          src={"http://s.lianmeihu.com/x-uploader/image/2021-07-08/3cd5ac47d3095668222e9a125fbdd681"}
          alt="LOGO"
        />
        
        <div className={cls(styles.menu, openStatus?styles.menuClick:null)} onClick={()=>toggleOpenStatus()}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <img
          onClick={()=>toggleOpenStatus()}
          className={cls(styles.menu, openStatus ? styles.menuClick : null)}
          src={
            "http://s.lianmeihu.com/x-uploader/image/2021-07-08/7b74f7e28dad81e138e916b8bdb3e27c"
          }
          alt="MENU"
        /> */}
      
        <div className={cls(styles.menuNav, openStatus ? styles.menuNavClick : null)} >
          <div className={styles.bgRight}>
            <div className={cls(styles.item,page === 'Home' ? styles.item_active:null)}>
              <div className={styles.title}>
                <Link
                  to="/index-mobile/"
                >
                  首页</Link>
              </div>
            </div>
            <div className={cls(styles.item,page === 'Property' ? styles.item_active:null)}>
              <div className={styles.title}>
              <Link
                  to="/property-mobile/"
                >
                  智慧物业</Link>
                  </div>
            </div>
            <div className={cls(styles.item,page === 'Life' ? styles.item_active:null)}>
            
              <div className={styles.title}>
              <Link
                  to="/life-mobile/"
                >
                  智慧生活</Link>
                  </div>
            </div>
            <div className={cls(styles.item,page === 'About' ? styles.item_active:null)}>
              <div className={styles.title}>
              <Link
                  to="/about-mobile/"
                >
                  关于我们</Link>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header