import React from "react";
import styles from "./index.module.css"

const Cloud = () => {
  return (
    <div className={styles.cloud}>
      <div className={styles.title}>云端大数据运营管理界面</div>
      <div className={styles.subTitle}>运营社区服务、运维关键数据可视，社区设备、人员<br/>核心数据沉淀，组织运营效率提升</div>
        <img
          className={styles.img1}
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-12/66869ec94a585b177163796c7f9b5ade"
          alt=""
          // style={{ width: 292, height: 190 }}
        />
        <img
          className={styles.img2}
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-12/0a5525af119fc6cc5f10417ff57a4bdc"
          alt=""
          // style={{ width: 159, height: 144 }}
        />

    </div>
  )
}
export default Cloud