import React from "react"
import PropTypes from "prop-types"
import Header from '../header'
import Footer from "../footer"

import "./index.css"

const Layout = ({children,page}) => {
  console.log('Page - 2' ,page)
  return (
    <>
      <Header page={page}/>
      <div>
      {children}
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout