import React from "react"
import styles from "./index.module.css"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <div className={styles.footerTitle}>
          <Link
            to="/index-mobile/"
            className={styles.footerTitle}
          >
            首页</Link>
        </div>
        <div className={styles.footerTitle}>
          <Link
            to="/property-mobile/"
            className={styles.footerTitle}
          >
            智慧物业</Link>
        </div>
        <div className={styles.footerTitle}>
        <Link
            to="/life-mobile/"
            className={styles.footerTitle}
          >
            智慧生活</Link>
          </div>
        <div className={styles.footerTitle}>
        <Link
            to="/about-mobile/"
            className={styles.footerTitle}
          >
            关于我们</Link>
            </div>
      </div>
      <div className={styles.box}>
        <div className={styles.company}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/370c0f5135f10d9f795ac7f95302bffb"
            alt=""
            
          />
          <div className={styles.desc}>杭州联每户网络科技有限公司</div>
        </div>
        <div className={styles.company}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/5a12b72c707cb7c651fa0e439d2a44cd"
            alt=""
           
          />
          <div className={styles.desc}>0571-87703081</div>
        </div>
        <div className={styles.company}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/5424ddc0b110d1cfbc0b74e41ac6890d"
            alt=""
            
          />
          <div className={styles.desc}>浙江省杭州市余杭区文一西路1288号海创科技中心1号楼1206</div>
        </div>
      </div>
      <div className={styles.wire}></div>
      <div className={styles.copyright}>© 2014-2021 杭州联每户网络科技有限公司  版权所有</div>
    </div>
  )
}

export default Footer