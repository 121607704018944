import React from "react";
import styles from "./index.module.css"

const Staff =()=>{
  return(
    <div className={styles.staff_mobile}>
      <div className={styles.staff_title}>物业员工APP</div>
      <div className={styles.staff_subTible}>简化工作流程、提升员工调度灵活性，高效管理团队</div>
      <img
        className={styles.staff_img1}
        src="http://s.lianmeihu.com/x-uploader/image/2021-07-13/1ff9f9520e246224becb18d74728574b"
        alt=""
      />
      <img
        className={styles.staff_img2}
        src="http://s.lianmeihu.com/x-uploader/image/2021-07-13/1e4964b0c22817950d42782795c39f9b"
        alt=""
      />
    </div>
  )
}

export default Staff