import React from 'react';
import styles from './index.module.css'

const Banner = ({ bannerUrl, imgLogo, containerStyle = {}, json }) => {
  return (
    <div className={styles.container}>
      <div className={styles.img1}>
        <img
          src={json.bannerUrl}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={styles.title}>
        {json.title}
      </div >
      <div className={styles.subTitle}>
        {json.subTitle}
      </div>
      <div className={styles.img2}>
        <img
          src={json.imgUrl}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={styles.icon}>
        <img
          src={json.iconUrl}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  )
}

export default Banner