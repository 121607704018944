import React from "react";
import styles from "./index.module.css"

const Community =()=>{
  return(
    <div className={styles.community}>
      <div className={styles.title}>数字化社区全景运营</div>
      <div className={styles.subTitle}>一套智能运营系统，360°全面赋能</div>
      <div className={styles.img1}>
        <img
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-27/be7d1e5429bdf78e8084eee51b5f85ef"
          alt=""
        />
      </div>
    </div>
  )
} 

export default Community