import React from "react";
import styles from "./index.module.css"

const Hardware =({json})=>{
  const list = [{
    subTitle:"品质硬件，经久耐用",
    desc: "防水防尘防潮，高亮度LED发光时长100000小时, 亮度可根据环境自适应调节"
  },{
    subTitle:"专利技术，安全可靠",
    desc: "落杆6级减速、水平落杆免支撑，防砸遇阻无须外接设备，拥有该产品12项发明及相关专利技术"
  },{
    subTitle:"广告投放，高效运营",
    desc: "广告叶片拔插易更换，广告内容、时段按需投放，灵活便捷，可面向多运营商"
  },{
    subTitle:"实时通讯，数据上云",
    desc: "开放式对接协议，支持异地同网管理，便于物业、地产集团化，可视化视图数据展示"
  }]
  return(
    <div className={styles.hardware_mobile}>
      <div className={styles.hardware_title}>推荐硬件</div>
      <img
      className={styles.hardware_img1}
      src="http://s.lianmeihu.com/x-uploader/image/2021-07-13/46abdb8b729bc6449b08b06ff52adf68"
      alt=""

      />
      <div className={styles.hardware_imgDesc}>智慧闸机</div>
      <div className={styles.hardware_word}>
        {list.map(item=>{
          return <div className={styles.hardware_word_item}>
            <div className={styles.hardware_subTitle}>{item.subTitle}</div>
            <div className={styles.hardware_desc}>{item.desc}</div>
          </div>
        })}
          
        {/* <div className={styles.hardware_subTitle}>专利技术，安全可靠</div>
        <div className={styles.hardware_desc}>落杆6级减速、水平落杆免支撑，防砸遇阻无须外接设备，拥有该产品12项发明及相关专利技术</div>
        
        <div className={styles.hardware_subTitle}>广告投放，高效运营</div>
        <div className={styles.hardware_desc}>广告叶片拔插易更换，广告内容、时段按需投放，灵活便捷，可面向多运营商</div>
        
        <div className={styles.hardware_subTitle}>实时通讯，数据上云</div>
        <div className={styles.hardware_desc}>开放式对接协议，支持异地同网管理，便于物业、地产集团化，可视化视图数据展示</div> */}
      </div>
    </div>
  )
}

export default Hardware