
import React from "react";
import Layout from "../components-mobile/layout";
import Banner from "../components-mobile/home/banner";
import Community from "../components-mobile/smart-property/community";
import Cloud from "../components-mobile/smart-property/cloud";
import Staff from "../components-mobile/smart-property/staff";
import Hardware from "../components-mobile/smart-property/hardware";

const Propertymobile = () => {
  const bannerJson = {
    title: '智慧物业',
    subTitle: '多维工具，打造数字化社区管理',
    bannerUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-12/70666a8d2adb158a8a22e11921058d0e',
    imgUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-12/4926f18d6a148e84f16c133dad895532',
    iconUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-12/67501e82a379ab09529598bdfedc532f'
  }

  return (
    <Layout  page="Property">
      <Banner json={bannerJson}/>
      <Community/>
      <Cloud/>
      <Staff/>
      <Hardware/>
    </Layout>
  )
}



export default Propertymobile